import $ from "jquery";
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  gsap
} from "gsap";

import './plugins';


window.$ = $;
window.Swiper = Swiper;
window.AOS = AOS;
window.gsap = gsap;



jQuery(window).on('load', function (event) {

	setTimeout(function() {
		AOS.init({
			duration: 250,
		});
	}, 1000);


	const swiperLatest = new Swiper('.latest__slider', {
		// slideClass: '.post',
		navigation: {
			nextEl: '.button-next',
		},
		slidesPerView: 1,
		breakpoints: {
			640: {
				slidesPerView: 2,
				spaceBetween: 40
			},
			960: {
				slidesPerView: 3,
				spaceBetween: 60
			}
		}
	});

	const videoSliders = document.querySelectorAll(".videos__wrapper");
	const videoSlidersNext = document.querySelectorAll(".button-next");

	for (let i = 0; i < videoSliders.length; i++) {
		videoSliders[i].classList.add("videos__wrapper-" + i);
		videoSlidersNext[i].classList.add("button-next-" + i);

		const swiperVideos = new Swiper(".videos__wrapper-" + i , {
			pagination: {
				el: ".swiper-pagination",
				type: "bullets",
			},
			navigation: {
				nextEl: ".button-next-" + i,
			},
			slidesPerView: 1,
			loop: true,
		});
	}

	// const swiperVideos = new Swiper('.videos__wrapper', {
	// 	// slideClass: '.post',
	// 	pagination: {
	// 		el: '.swiper-pagination',
	// 		type: 'bullets',
	// 	},
	// 	navigation: {
	// 		nextEl: '.button-next',
	// 	},
	// 	slidesPerView: 1,
	// 	loop:true,
	// });



	const swiperHero = new Swiper('.hero__swiper', {
		slidesPerView: 1,
		loop:true,
		autoplay: {
			delay: 8000,
		},
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
	});

	const swiperCoaches = new Swiper('.coach__swiper', {
		slidesPerView: 3,
		loop:false,
		navigation: {
			nextEl: '.swiper--nav__next',
			prevEl: '.swiper--nav__prev',
		},
		// slideClass: 'coach',
		spaceBetween: 76,
		breakpoints: {
			300: {
				slidesPerView: 1,
				spaceBetween: 32
			},
			600: {
				slidesPerView: 2
			},
			900: {
				slidesPerView: 3,
				spaceBetween: 76
			}
		}
	});

	//Mobile menu

	// Check for animation set up
	if ($(window).innerWidth() < 980) {
		$('.menu-header-menu-container').attr('data-aos', 'fade-up');
		$('.menu-top-menu-container').attr('data-aos', 'fade-right');
		$('.mobile__wrapper .btn.book').attr('data-aos', 'fade-right');
	}

	$('.mobile__hamburger').on('click', function() {
		$('.mobile__wrapper').addClass('active');

		$('.mobile__wrapper').removeClass('aos-animate');
		$('.menu-header-menu-container').removeClass('aos-animate');
		$('.menu-top-menu-container').removeClass('aos-animate');
		$('.mobile__wrapper .btn.book').removeClass('aos-animate');

		setTimeout(function() {
			$('.mobile__wrapper').addClass('aos-animate');
		}, 100);

		setTimeout(function() {
			// $('.mobile__wrapper').addClass('aos-animate');
			$('.menu-header-menu-container').addClass('aos-animate');
			$('.menu-top-menu-container').addClass('aos-animate');
			$('.mobile__wrapper .btn.book').addClass('aos-animate');
		}, 500);

	});
	$('.mobile__close').on('click', function() {
		$('.mobile__wrapper').removeClass('active');
	});

	// Book now v2
	$("a").on("click", function (e) {
		var mod = $(this).prop("href");
		console.log(mod);
		if (mod.indexOf("bookwhen.com") != -1) {
			e.preventDefault();

			$("#html").addClass("noscroll");
			$(".book__container").addClass("active");

			setTimeout(function () {
				var $iframe = $("#bookFrame");
				if ($iframe.length) {
					$iframe.attr("src", mod);
					return false;
				}
				return true;
			}, 1000);
		}
	});

	//Book now
	// $('.book__link a').on('click', function(e) {
	// 	var mod = $(this).prop('href');

	// 	if(mod.indexOf('wa.me') != -1) {

	// 	} else {
	// 		e.preventDefault();


	// 		$('#html').addClass('noscroll');
	// 		$('.book__container').addClass('active');

	// 		setTimeout(function() {
	// 			var $iframe = $('#bookFrame');
	// 			if ($iframe.length) {
	// 				$iframe.attr('src',mod);
	// 				return false;
	// 			}
	// 			return true;
	// 		}, 1000);
	// 	}

	// });

	$('.book__container .close, .book__container .overlay').on('click', function(e) {
		$('.book__container').removeClass('active');
		$('#html').removeClass('noscroll');
	});

//new start
	$('button.book').on('click',function() {
			$('.book__modal').addClass('active');
	});

	$(document).on('mouseup', function (e) {
		var container = new Array();
		container.push($('.book__modal'));

		$.each(container, function(key, value) {
			if (!$(value).is(e.target) // if the target of the click isn't the container...
				&& $(value).has(e.target).length === 0) // ... nor a descendant of the container
			{
				// $(value).hide();
				$(value).removeClass('active');
			}
		});
	});

//new end

	//multi content
	$('.multi .content__container .row button').on('click', function() {
		$('html').addClass('noscroll');
		$('.modal').removeClass('active');
		$('.modal__content').removeClass('active');

		var modalRow = $(this).attr('data-row'),
				modal = $(this).attr('data-content');
		console.log(modal);

		$('.modal[data-row="'+modalRow+'"]').addClass('active');
		$('.modal__content[data-content="'+modal+'"]').addClass('active');
	});

	$('.modal__container .close').on('click', function() {
		$('html').removeClass('noscroll');
		$('.modal').removeClass('active');
		$('.modal__content').removeClass('active');
		$('.book__modal').removeClass('active');
	});


    $(".tabs__content .tab").hide();
    $(".tabs__content .tab:first-of-type").show();
	$('.tabs__top ul li:first-of-type').addClass('active');
	$('.tabs__content h3:first-of-type').addClass('active');

	//Tab mode
	$('.tabs__top ul li').on('click', function() {

		$(".tabs__content .tab").hide();
		var tab = $(this).attr("data-tab");
		$('.tab[data-tab="'+tab+'"]').fadeIn();

		$(".tabs__top ul li").removeClass("active");
		$(this).addClass("active");

		$('.tabs__content h3').removeClass('active');
		$(".tabs__content h3[rel^='"+tab+"']").addClass("active");

	});

	// Drawer mode
	$('.tabs__content h3').on('click', function() {
		$(".tabs__content .tab").hide();

		var tabHead = $(this).attr('data-tab');
		$('.tab[data-tab="'+tabHead+'"]').fadeIn();

		$('.tabs__content h3').removeClass('active');
		$(this).addClass("active");

		$(".tabs__top ul li").removeClass("active");
		$('.tabs__top ul li[data-tab="'+tabHead+'"]').addClass("active");
	});


	// Categories
	$('.archive__cats--inner p').on('click', function() {
		$(this).toggleClass('open');
		$('.cat-list').toggleClass('active');
	});

	$('.cat-list_item').on('click', function() {

		//remove open filter
		$('.archive__cats--inner p').toggleClass('open');
		$('.cat-list').toggleClass('active');

		$('.cat-list_item').removeClass('active');
		$(this).addClass('active');

		var cat = $(this).data('slug');

		// console.log(cat);
		var params = {
			action: 'filter_posts',
			category: cat,
		}

		$.ajax({
			type: 'GET',
			url: '/wp-admin/admin-ajax.php',
			dataType: 'html',
			data: params,
			success: function(res) {
				$('.archive__posts').empty();
				$('.archive__posts').html(res);
				$('.load__wrapper .btn').attr('data-slug', cat);
				$('.load__wrapper .btn').show();
				$('.load__wrapper .btn').attr('data-page', 2);
				// console.log(params);
			}
		})
	});


	$('.load__wrapper .btn').on('click', function() {

		var page = parseInt($(this).attr('data-page')),
				cat = $(this).attr('data-slug'),
				params = {
					action: 'load_posts',
					paged: page,
					cat: cat
				}


		$.ajax({
			type: 'GET',
			url: '/wp-admin/admin-ajax.php',
			dataType: 'html',
			data: params,
			success: function(response) {

				if(!response) {
					// console.log('invalid');
					$('.load__wrapper .btn').hide();
				} else {
					// console.log('valid');
					$('.archive__posts').append(response);
					$('.load__wrapper .btn').attr('data-page', (page+1))
				}
				// console.log(response);
			}
		})

		// console.log(params);

	});


});

(function( $ ) {

	function initMap( $el ) {

		// Find marker elements within map.
		var $markers = $el.find('.marker');

		// Create gerenic map.
		var mapArgs = {
			zoom        : $el.data('zoom') || 16,
			mapTypeId   : google.maps.MapTypeId.ROADMAP,
			streetViewControl: false,
			mapTypeControl: false,
			styles: [{"featureType":"administrative","elementType":"all","stylers":[{"saturation":"-100"}]},{"featureType":"administrative.province","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","elementType":"all","stylers":[{"saturation":-100},{"lightness":"50"},{"visibility":"simplified"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":"-100"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"lightness":"30"}]},{"featureType":"road.local","elementType":"all","stylers":[{"lightness":"40"}]},{"featureType":"transit","elementType":"all","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]},{"featureType":"water","elementType":"labels","stylers":[{"lightness":-25},{"saturation":-100}]}]
		};
		var map = new google.maps.Map( $el[0], mapArgs );

		// Add markers.
		map.markers = [];
		$markers.each(function(){
			initMarker( $(this), map );
		});

		// Center map based on markers.
		centerMap( map );

		// Return map instance.
		return map;
	}

	function initMarker( $marker, map ) {

		// Get position from marker.
		var lat = $marker.data('lat');
		var lng = $marker.data('lng');
		var latLng = {
			lat: parseFloat( lat ),
			lng: parseFloat( lng )
		};

		// Create marker instance.
		var marker = new google.maps.Marker({
			position : latLng,
			map: map,
			icon: '/wp-content/themes/all-in/src/images/icon-map-marker.svg'
		});

		// Append to reference for later use.
		map.markers.push( marker );

		// If marker contains HTML, add it to an infoWindow.
		if( $marker.html() ){

			// Create info window.
			var infowindow = new google.maps.InfoWindow({
				content: $marker.html()
			});

			// Show info window when marker is clicked.
			google.maps.event.addListener(marker, 'click', function() {
				infowindow.open( map, marker );
			});
		}
	}

	function centerMap( map ) {

		// Create map boundaries from all map markers.
		var bounds = new google.maps.LatLngBounds();
		map.markers.forEach(function( marker ){
			bounds.extend({
				lat: marker.position.lat(),
				lng: marker.position.lng()
			});
		});

		// Case: Single marker.
		if( map.markers.length == 1 ){
			map.setCenter( bounds.getCenter() );

		// Case: Multiple markers.
		} else{
			map.fitBounds( bounds );
		}
	}

	// Render maps on page load.
	$(document).ready(function(){
		$('.acf-map').each(function(){
			var map = initMap( $(this) );
		});
	});

})(jQuery);
